
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import { useI18n } from "vue-i18n/index";
import dayjs from "dayjs";
//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";
import axios from "axios";
import { ElMessage } from "element-plus";
import moment from "moment";
import { number } from "yup/lib/locale";
import store from "@/store";

export default defineComponent({
  name: "reportPage",

  computed: {},
  methods: {
    clcikDraw() {
      this.retire1 = true
      this.retire2 = true
    },
    scoreFunc(score) {
      score = JSON.parse(score)
      var scoreText = ""

      if (score[0][0] || score[0][1]) { scoreText += Number(score[0][0]) + ":" + Number(score[0][1]) + " " }
      if (score[0][2] || score[0][3]) { scoreText += "(" + Number(score[0][2]) + ":" + Number(score[0][3]) + ")" }
      if (score[1][0] || score[1][1]) { scoreText += "; " + Number(score[1][0]) + ":" + Number(score[1][1]) + " " }
      if (score[1][2] || score[1][3]) { scoreText += "(" + Number(score[1][2]) + ":" + Number(score[1][3]) + ")" }
      if (score[2][0] || score[2][1]) { scoreText += "; " + Number(score[2][0]) + ":" + Number(score[2][1]) + " " }
      if (score[2][2] || score[2][3]) { scoreText += "(" + Number(score[2][2]) + ":" + Number(score[2][3]) + ")" }

      return scoreText
    },
    submit() {
      var tempDate = moment(this.reportDate).format("YYYY-MM-DD");
      var tempTime = moment(this.reportTime, "H").format("HH:mm:ss");
      if (tempDate == "Invalid date") {
        tempDate = "0000-00-00";
      }
      this.inputTime = tempDate + " " + tempTime;

      this.disableSubmit = true;
      axios
        .post(
          process.env.VUE_APP_API_URL +
          "&mode=jwt&tab=report&action=submit&type=" +
          this.type,
          JSON.stringify({
            lang: this.$i18n.locale,
            type: this.type,
            gameid: this.gameID,
            tourid: this.data.tour_id,
            groupid: this.data.group_id,
            datetime: this.inputTime,
            place: this.place,
            score: this.score,
            retire1: this.retire1,
            retire2: this.retire2,
            newtime: this.newtime,
            remark: this.remark,
          })
        )
        .then((response) => {
          this.disableSubmit = false;
          if (response.data.message === "cantUpdate") {
            ElMessage.error(this.$t("updateFailed"));
          } else if (response.data.message === "haveWinner" || response.data.message === "errorConfirmed") {
            ElMessage.error(this.$t("haveWinner"));
          } else if (response.data.message === "updateSuccess") {
            ElMessage.success(this.$t("updated"));
            this.$router.push({ name: 'schedule', params: { page: 1 } });
          } else {
            ElMessage.error(this.$t("updateFailed"));
          }
          this.$router.push({ name: 'schedule', params: { page: 1 } });
          this.getGameData();
        })
        .catch((error) => {
          // handle error
          this.disableSubmit = false;
          ElMessage.error(this.$t("updateFailed") + ": " + error);
        });
    },
    /*
    myClickEvent() {
      document.getElementById("startDate").click();
    },
    */
    getGameData() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
          "&mode=jwt&tab=report&action=data&type=" +
          this.type +
          "&gameid=" +
          this.gameID,
          JSON.stringify({ lang: this.$i18n.locale })
        )
        .then((response) => {
          console.log(response.data)
          const lastUpdate = new Date(response.data.data.last_update);
          const now = new Date();
          if (!isNaN(lastUpdate.getTime())) {
            response.data.data.lastUpdateDays = (now.getTime() - lastUpdate.getTime()) / (1000 * 60 * 60 * 24);
          }
          this.data = response.data.data;
          this.groupdata = response.data.groupdata;
          this.editData = response.data.editData;
          if (!response.data.data.game_id && !response.data.data.double_id)
            this.showError = true;
          this.place = response.data.data.place;
          this.remark = response.data.data.remark;
          var dateTimeVal = "";
          if (this.type === "single" && response.data.data.datetime) { dateTimeVal = response.data.data.datetime }
          if (this.type === "double" && response.data.data.time) { dateTimeVal = response.data.data.time }

          this.reportDate = moment(
            dateTimeVal,
            "YYYY-MM-DD hh:mm:ss"
          ).format("YYYY-MM-DD");
          this.reportTime = Number(
            moment(
              dateTimeVal.substring(11),
              "hh:mm:ss"
            ).format("H")
          );

          if (dateTimeVal.substring(0, 10) == '0000-00-00' && this.reportTime == 0) { this.reportTime = '' }

          if (response.data.data.retire1 === "1") this.retire1 = true;
          else this.retire1 = false;
          if (response.data.data.retire2 === "1") this.retire2 = true;
          else this.retire2 = false;
          if (response.data.data.newtime === "1") this.newtime = true;
          else this.newtime = false;
          // Handle score
          if (!response.data.data.score) {
            this.score = [
              ["", "", "", ""],
              ["", "", "", ""],
              ["", "", "", ""],
            ];
          } else {
            this.score = response.data.data.score;
          }
          //this.maxPage = response.data.maxPage;
        })
        .catch((error) => {
          // handle error
          this.showError = true;
          ElMessage.error(this.$t("error") + ": " + error);
        });
    },
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({ name: 'login' });
    }
    this.reportTime = "";
    this.getGameData();
  },
  data() {
    return {
      tourContent: "",
      uid: store.state.uid,
      data: { tour_id: "", group_id: "" },
      groupdata: "",
      editData: [],
      place: "",
      score: [
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
      ],
      retire1: false,
      retire2: false,
      newtime: false,
      date: "",
      disableSubmit: false,
      showError: false,
      remark: "",
      inputTime: {},
      gameID: Number(this.$route.params.id),
      type: this.$route.params.type,
      pagination: {
        rowsPerPage: 20, // current rows per page being displayed
      },
      reportDate: "",
      reportTime: {},
      hourOptions: [
        { value: '', label: "---" },
        { value: 7, label: "07:00 AM" },
        { value: 8, label: "08:00 AM" },
        { value: 9, label: "09:00 AM" },
        { value: 10, label: "10:00 AM" },
        { value: 11, label: "11:00 AM" },
        { value: 12, label: "12:00 PM" },
        { value: 13, label: "01:00 PM" },
        { value: 14, label: "02:00 PM" },
        { value: 15, label: "03:00 PM" },
        { value: 16, label: "04:00 PM" },
        { value: 17, label: "05:00 PM" },
        { value: 18, label: "06:00 PM" },
        { value: 19, label: "07:00 PM" },
        { value: 20, label: "08:00 PM" },
        { value: 21, label: "09:00 PM" },
        { value: 22, label: "10:00 PM" },
        { value: 23, label: "11:00 PM" },
        { value: 0, label: "12:00 AM" },
        { value: 1, label: "01:00 AM" },
        { value: 2, label: "02:00 AM" },
        { value: 3, label: "03:00 AM" },
        { value: 4, label: "04:00 AM" },
        { value: 5, label: "05:00 AM" },
        { value: 6, label: "06:00 AM" },
      ],
    };
  },
});
